/* eslint-disable xss/no-mixed-html */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  event: {
    id: 'Events.Event',
    defaultMessage: 'Event',
  },
  events: {
    id: 'Events.Heading',
    defaultMessage: 'Events',
  },
  region: {
    id: 'Events.Region',
    defaultMessage: 'Region',
    description: 'Shown next to area names, e.g Region: London',
  },
  address: {
    id: 'Events.Address',
    defaultMessage: 'Address',
    description: 'Title of address section in detail bar',
  },
  locationGuide: {
    id: 'Events.LocationGuide',
    defaultMessage: 'RA {areaName} guide',
  },
  viewLocationGuide: {
    id: 'Events.ViewLocationGuide',
    defaultMessage: 'View {areaName} guide',
  },
  date: {
    id: 'Events.Date',
    defaultMessage: 'Date',
    description: 'Title of date section in detail bar',
  },
  promoter: {
    id: 'Events.Promoter',
    defaultMessage: '{promoterCount, plural, one {Promoter} other {Promoters}}',
    description: 'Title of promoter section in detail bar',
  },
  singlePromoter: {
    id: 'Events.SinglePromoter',
    defaultMessage: 'Promoter',
  },
  promoters: {
    id: 'Events.Promoters',
    defaultMessage: 'Promoters',
    description: 'Title of promoters section',
  },
  minimumAge: {
    id: 'Events.MinAge',
    defaultMessage: 'Min. age',
    description: 'Title of minimum age section in detail bar',
  },
  minimumAgePolicy: {
    id: 'Events.MinimumAgePolicy',
    defaultMessage: '{venueName} is an over {minimumAge} venue',
    description: 'Displaying the clubs minimum age policy',
  },
  cost: {
    id: 'Events.Cost',
    defaultMessage: 'Cost',
    description: 'Title of cost section in detail bar',
  },
  upcomingEvents: {
    id: 'Events.UpcomingEvents',
    defaultMessage: 'Upcoming events',
  },
  upcomingEventsSubnavTitle: {
    id: 'Events.UpcomingEventsSubnavTitle',
    defaultMessage: 'Upcoming Events',
  },
  upcomingEventsCount: {
    id: 'Events.UpcomingEventsCount',
    defaultMessage: `{count} upcoming events`,
  },
  pastEvents: {
    id: 'Events.PastEvents',
    defaultMessage: 'Past events',
    description: 'Title of past events sub section listing',
  },
  pastEventsSubnavTitle: {
    id: 'Events.PastEventsSubnavTitle',
    defaultMessage: 'Past Events',
  },
  moreOn: {
    id: 'Events.MoreOn',
    defaultMessage: 'More on {name}',
    description:
      'Title of "More on" section on event detail page - e.g. "More on Fabric"',
  },
  additionalInfo: {
    id: 'Events.AdditionalInfo',
    defaultMessage: 'Additional info',
    description:
      'The additional information on an event, currently used for age restrictions',
  },
  promotionalLinks: {
    id: 'Events.PromotionalLinks',
    defaultMessage: 'Promotional links',
    description:
      'A list of links the promoter can provide to show on the event description',
  },
  raPick: {
    id: 'Events.RAPick',
    defaultMessage: 'RA Pick',
    description: 'Smaller heading used to show RA Pick text on an event',
  },
  RAPicks: {
    id: 'Events.RAPicks',
    defaultMessage: 'RA Picks',
  },
  RAPicksWithLocation: {
    id: 'Events.RAPicksWithLocation',
    defaultMessage: 'RA Picks in {areaName}',
  },
  areYouAPromoter: {
    id: 'Events.AreYouAPromoter',
    defaultMessage: 'Are you a promoter?',
  },
  submitEvent: {
    id: 'Events.SubmitEvent',
    defaultMessage: 'Submit event',
  },
  viewAllToday: {
    id: 'Events.ViewAllToday',
    defaultMessage: 'View all today',
  },
  viewMoreArchive: {
    id: 'Events.ViewMoreArchive',
    defaultMessage: 'View more archive',
  },
  viewMoreEvents: {
    id: 'Events.ViewMoreEvents',
    defaultMessage: 'View more events',
    description: 'Shown on an artists upcoming events section',
  },
  archiveOfEvents: {
    id: 'Events.ArchiveOfEvents',
    defaultMessage: 'Archive of events',
  },
  interested: {
    id: 'Events.Interested',
    defaultMessage: 'Interested',
    description:
      'Used on the button where user can register their interest for an event',
  },
  raEditorial: {
    id: 'Events.RaEditorial',
    defaultMessage: 'RA Editorial',
    description: 'Used for the Clubs Details Sub Nav',
  },
  raSays: {
    id: 'Events.RaSays',
    defaultMessage: 'RA Says',
    description:
      'Used on the clubs page where we put RA Editorial comments about the venue',
  },
  viewProfile: {
    id: 'Events.ViewProfile',
    defaultMessage: 'View profile',
    description: 'Used as text for links that take user to a profile page',
  },
  promoterNews: {
    id: 'Events.PromoterNews',
    defaultMessage: 'Promoter News',
    description: 'The heading for the promoter news section',
  },
  clubNews: {
    id: 'Events.ClubNews',
    defaultMessage: 'Club News',
    description: 'The heading for the club news section',
  },
  viewMoreNews: {
    id: 'Events.ViewMoreNews',
    defaultMessage: 'View more news',
    description: 'Button text for viewing more news',
  },
  established: {
    id: 'Events.Established',
    defaultMessage: 'Established',
    description: 'Club stat for when a club was first established',
  },
  capacity: {
    id: 'Events.Capacity',
    defaultMessage: 'Capacity',
    description: 'Club stat for a clubs capacity',
  },
  mostListedArtists: {
    id: 'Events.MostListedArtists',
    defaultMessage: 'Most listed artists',
    description: 'Club stat for a clubs most listed artists',
  },
  eventsSoFarThisYear: {
    id: 'Events.EventsSoFarThisYear',
    defaultMessage: 'Events so far this year',
    description: 'Club stat for the number of events a club has had this year',
  },
  noPastEventListings: {
    id: 'Events.NoPastEventListings',
    defaultMessage: 'This club does not have any past event listings yet.',
    description: 'Shown on the clubs page under the archive section',
  },
  noPastArtistEventListings: {
    id: 'Events.NoPastArtistEventListings',
    defaultMessage: 'This artist does not have any past event listings yet.',
    description: 'Show on the artist page under the archive section',
  },
  noUpcomingEventListings: {
    id: 'Events.NoUpcomingEventListings',
    defaultMessage: 'There are currently no upcoming events listed.',
    description: 'Shown on the clubs page under the upcoming events section',
  },
  lineup: {
    id: 'Events.Lineup',
    defaultMessage: 'Lineup',
    description: 'Title for lineup section of event detail page',
  },
  popular: {
    id: 'Events.Popular',
    defaultMessage: 'Popular',
    description: 'Title for popular events listing',
  },
  popularEvents: {
    id: 'Events.PopularEvents',
    defaultMessage: 'Popular events',
  },
  popularWithLocation: {
    id: 'Events.PopularWithLocation',
    defaultMessage: 'Popular in {areaName}',
    description: 'Title for popular events in location listing',
  },
  popularClubs: {
    id: 'Events.PopularClubs',
    defaultMessage: 'Popular Clubs',
    description: 'Title for popular club search results',
  },
  popularClubsWithLocation: {
    id: 'Events.popularClubsWithLocation',
    defaultMessage: 'Popular Clubs in {areaName}',
    description: 'Title for popular club in location listing',
  },
  allClubs: {
    id: 'Events.AllClubs',
    defaultMessage: 'All Clubs',
    description: 'Title for all clubs in location listng',
  },
  popularArtists: {
    id: 'Events.PopularArtists',
    defaultMessage: 'Popular Artists',
    description: 'Title for popular artist search results',
  },
  popularPromoters: {
    id: 'Events.PopularPromoters',
    defaultMessage: 'Popular Promoters',
    description: 'Title for popular promoter search results',
  },
  popularPromotersWithLocation: {
    id: 'Events.PopularPromotersWithLocation',
    defaultMessage: 'Popular Promoters in {areaName}',
    description: 'Title for popular promoter in location listing',
  },
  allPromoters: {
    id: 'Events.AllPromoters',
    defaultMessage: 'All Promoters',
    description: 'Title for all promoters in location listing',
  },
  popularClubsInLondon: {
    id: 'Events.PopularClubsInLondon',
    defaultMessage: 'Popular Clubs in London',
    description: 'Title for popular clubs pre-fill search results',
  },
  nearMe: {
    id: 'Events.nearMe',
    defaultMessage: 'Near Me',
    description: 'Title for clubs near me pre-fill search results',
  },
  regionsMostPlayed: {
    id: 'Events.regionsMostPlayed',
    defaultMessage: 'Regions most played',
    description: 'Title for "regions most played" stat on artist pages',
  },
  clubsMostPlayed: {
    id: 'Events.clubsMostPlayed',
    defaultMessage: 'Clubs most played',
    description: 'Title for "clubs most played" stat on artist pages',
  },
  firstEventOnRA: {
    id: 'Events.firstEventOnRA',
    defaultMessage: 'First event on RA',
    description: 'Title for "first event date" stat on artist pages',
  },
  bookingInformation: {
    id: 'Events.bookingInformation',
    defaultMessage: 'Booking information',
    description: 'Booking information displayed on an artists page',
  },
  stats: {
    id: 'Events.Stats',
    defaultMessage: 'Stats',
    description: 'Title for stats section on club page',
  },
  clubs: {
    id: 'Events.Clubs',
    defaultMessage: 'Clubs',
    description: 'Title for clubs listing page',
  },
  club: {
    id: 'Events.Club',
    defaultMessage: 'Club',
  },
  aboutRegion: {
    id: 'Events.AboutRegion',
    defaultMessage: 'About {region}',
  },
  otherRegions: {
    id: 'Events.OtherRegions',
    defaultMessage: 'Other Regions',
  },
  venue: {
    id: 'Events.Venue',
    defaultMessage: 'Venue',
    description: 'Title of venue section in detail bar',
  },
  venues: {
    id: 'Events.Venues',
    defaultMessage: 'Venues',
  },
  population: {
    id: 'Events.Population',
    defaultMessage: 'Population',
  },
  buyTickets: {
    id: 'Events.BuyTickets',
    defaultMessage: 'Buy tickets',
    description: 'Messaged displayed on the button used to buy tickets',
  },
  getTicket: {
    id: 'Events.GetTicket',
    defaultMessage: 'Get ticket',
    description:
      'Messaged displayed on the button used to buy tickets when the ticket is free',
  },
  payTerms: {
    id: 'Events.PayTerms',
    defaultMessage: `By proceeding with the payment you agree to our <terms>Terms</terms>`,
  },
  choosePayment: {
    id: 'Events.ChoosePayment',
    defaultMessage: 'Choose payment',
  },
  biography: {
    id: 'Dict.Biography',
    defaultMessage: 'Biography',
  },
  selectedDiscography: {
    id: 'Dict.SelectedDiscography',
    defaultMessage: 'Selected discography',
  },
  youHaventBoughtTickets: {
    id: 'Events.YouHaventBoughtTickets',
    defaultMessage: "You haven't bought any tickets yet!",
    description:
      "Shown a users orders page when they haven't bought any tickets yet",
  },
  viewEvents: {
    id: 'Events.ViewEvents',
    defaultMessage: 'View events',
    description: 'Text for button to take you to the event listing page',
  },
  viewTickets: {
    id: 'Events.ViewTickets',
    defaultMessage: 'View tickets',
  },
  tickets: {
    id: 'Events.Tickets',
    defaultMessage: 'Tickets',
  },
  eventTitle: {
    id: 'Events.EventTitle',
    defaultMessage: 'Event title',
  },
  eventDate: {
    id: 'Events.EventDate',
    defaultMessage: 'Event date',
  },
  raTickets: {
    id: 'Events.RATickets',
    defaultMessage: 'RA Tickets',
  },
  raNews: {
    id: 'Events.RANews',
    defaultMessage: 'RA News',
    description: 'Used for the Clubs Details Sub Nav',
  },
  eventAdmin: {
    id: 'Events.EventAdmin',
    defaultMessage: 'Event admin',
  },
  updateThisEvent: {
    id: 'Events.UpdateThisEvent',
    defaultMessage: 'Update this event',
  },
  lastUpdated: {
    id: 'Events.LastUpdated',
    defaultMessage: 'Last updated',
  },
  flyerFront: {
    id: 'Events.FlyerFront',
    defaultMessage: 'Flyer front',
  },
  flyerBack: {
    id: 'Events.FlyerBack',
    defaultMessage: 'Flyer back',
  },
  overview: {
    id: 'Events.Overview',
    defaultMessage: 'Overview',
  },
  all: {
    id: 'Events.All',
    defaultMessage: 'All',
  },
  nearYou: {
    id: 'Events.NearYou',
    defaultMessage: 'Near you',
  },
  viewAllLocations: {
    id: 'Events.ViewAllLocations',
    defaultMessage: 'View all locations',
  },
  viewCityGuide: {
    id: 'Events.ViewCityGuide',
    defaultMessage: 'View city guide',
  },
  nextEvent: {
    id: 'Events.NextEvent',
    defaultMessage: 'Next event',
  },
  noUpcomingEvents: {
    id: 'Events.NoUpcomingEvents',
    defaultMessage: 'No upcoming events',
  },
  interestedCount: {
    id: 'Events.interestedCount',
    defaultMessage: 'interestedCount',
  },
  genre: {
    id: 'Events.Genre',
    defaultMessage: '{genreCount, plural, one {Genre} other {Genres}}',
    description: 'Title of genre section in event details',
  },
  ticketspurchased: {
    id: 'Events.TicketsPurchased',
    defaultMessage: 'You have purchased tickets to this event',
  },
  viewyourorder: {
    id: 'Events.ViewYourOrder',
    defaultMessage: 'View your order',
  },
  aboutResale: {
    id: 'Events.AboutResale',
    defaultMessage: 'About resale',
  },
  eventSoldOutResaleActive: {
    id: 'Events.EventSoldOutResaleActive',
    defaultMessage:
      'This event has sold out and the <resaleactivelink>resale queue is active</resaleactivelink>.',
  },
  checkBackToPurchaseTicketsAsTheyBecomeAvailable: {
    id: 'Events.CheckBackToPurchaseTicketsAsTheyBecomeAvailable',
    defaultMessage: 'Check back to purchase tickets as they become available.',
  },
  resaleCopy: {
    id: 'Events.ResaleCopy',
    defaultMessage:
      'The RA Tickets resale service is a secure way to buy and sell tickets for sold-out events. If you buy or sell a ticket through our resale system, the revenue goes directly to those that deserve it: the people running the event.',
  },
  questionAboutTheEvent: {
    id: 'Events.QuestionAboutTheEvent',
    defaultMessage: 'Do you have a question about the event?',
  },
  problemWithTheEvent: {
    id: 'Events.ProblemWithTheEvent',
    defaultMessage: 'Was there a problem with the event?',
  },
  contactThePromoter: {
    id: 'Events.ContactThePromoter',
    defaultMessage: 'Contact the promoter',
  },
  contactRATicketSupport: {
    id: 'Events.ContactRATicketSupport',
    defaultMessage: 'Contact RA ticket support',
  },
  viewPastEventsInstead: {
    id: 'Events.ViewPastEventsInstead',
    defaultMessage: 'View past events instead',
  },
  typeVenueName: {
    id: 'Events.TypeVenueName',
    defaultMessage: 'Type venue name',
  },
  showBookingInfo: {
    id: 'Events.ShowBookingInfo',
    defaultMessage: 'Show booking info',
  },
  revision: {
    id: 'Events.Revision',
    defaultMessage: 'Revision',
  },
  submittedBy: {
    id: 'Events.SubmittedBy',
    defaultMessage: 'Submitted by',
  },
  submittedAt: {
    id: 'Events.SubmittedAt',
    defaultMessage: 'Submitted at',
  },
  approvedBy: {
    id: 'Events.ApprovedBy',
    defaultMessage: 'Approved by',
  },
  revisionWithNumber: {
    id: 'Events.RevisionWithNumber',
    defaultMessage: 'Revision {number}',
  },
  thisClubIsPermanentlyClosed: {
    id: 'Events.ThisClubIsPermanentlyClosed',
    defaultMessage: 'This club is permanently closed',
  },
  festivals: {
    id: 'Events.Festivals',
    defaultMessage: 'Festivals',
  },
  allFestivals: {
    id: 'Events.AllFestivals',
    defaultMessage: 'All festivals',
  },
  discoverFestivals: {
    id: 'Events.DiscoverFestivals',
    defaultMessage:
      'Discover the best electronic music festivals around the world.',
  },
  discoverFestivalsCountry: {
    id: 'Events.DiscoverFestivalsCountry',
    defaultMessage:
      'Discover the best electronic music festivals in and around {country}.',
  },
  forYou: {
    id: 'Events.ForYou',
    defaultMessage: 'For you',
  },
  justAnnounced: {
    id: 'Events.JustAnnounced',
    defaultMessage: 'Just announced',
  },
  announcedToday: {
    id: 'Events.AnnouncedToday',
    defaultMessage: 'Announced today',
  },
  announcedYesterday: {
    id: 'Events.AnnouncedYesterday',
    defaultMessage: 'Announced yesterday',
  },
  announcedXDaysAgo: {
    id: 'Events.AnnouncedXDaysAgo',
    defaultMessage:
      'Announced {daysSinceAnnounced} {daysSinceAnnounced, plural, one {day} other {days}} ago',
  },
  announcedXMonthsAgo: {
    id: 'Events.AnnouncedXMonthsAgo',
    defaultMessage:
      'Announced {monthsSinceAnnounced} {monthsSinceAnnounced, plural, one {month} other {months}} ago',
  },
  announcedXYearsAgo: {
    id: 'Events.AnnouncedXYearsAgo',
    defaultMessage:
      'Announced {yearsSinceAnnounced} {yearsSinceAnnounced, plural, one {year} other {years}} ago',
  },
  broadenYourSearch: {
    id: 'Events.BroadenYourSearch',
    defaultMessage: "Let's broaden your search",
  },
  noNewUpcomingEvents: {
    id: 'Events.NoNewUpcomingEvents',
    defaultMessage: 'There are no new events',
  },
  followMore: {
    id: 'Events.FollowMore',
    defaultMessage:
      'Follow some Artists, Promoters and Clubs and their events will appear here',
  },
  unauthenticatedTitle: {
    id: 'Events.UnauthenticatedTitle',
    defaultMessage:
      'Get personalised event recommendations by following your favourite artists and promoters',
  },
  unauthenticatedMessage: {
    id: 'Events.UnauthenticatedMessage',
    defaultMessage:
      'You need to have an account with RA so we can save your preferences.',
  },
  secretVenueInfo: {
    id: 'Events.SecretVenueInfo',
    defaultMessage:
      'Venue details will be emailed to ticket holders before the event',
  },
  allGenresWithCount: {
    id: 'Magazine.AllGenresWithCount',
    defaultMessage: 'All genres ({count})',
  },
  searchForAGenre: {
    id: 'Magazine.SearchForAGenre',
    defaultMessage: 'Search for a genre',
  },
  genreWithCount: {
    id: 'Events.GenreWithCount',
    defaultMessage: 'Genre ({count})',
  },
  ticketsForThisEventAreSoldOut: {
    id: 'Events.TicketsForThisEventAreSoldOut',
    defaultMessage: 'Tickets for this event are sold out',
  },
  getNotifiedWhenATicketBecomesAvailable: {
    id: 'Events.GetNotifiedWhenATicketBecomesAvailable',
    defaultMessage: 'Get notified when a ticket becomes available',
  },
  subscribeToTicketNotifications: {
    id: 'Events.SubscribeToTicketNotifications',
    defaultMessage: 'Subscribe to ticket notifications',
  },
  unsubscribeFromTicketNotifications: {
    id: 'Events.UnsubscribeFromTicketNotifications',
    defaultMessage: 'Unsubscribe from ticket notifications',
  },
  ticketNotificationsConfirmation: {
    id: 'Events.TicketNotificationsConfirmation',
    defaultMessage:
      'We’ll send you a notification to {email} whenever a ticket becomes available. Be quick, tickets are sold on a first come, first served basis.',
  },
  sorryTheTicketHasAlreadyBeenPurchased: {
    id: 'Events.SorryTheTicketHasAlreadyBeenPurchased',
    defaultMessage:
      'Sorry, the ticket has already been purchased. We will email you again when another ticket is available.',
  },
  travelOptions: {
    id: 'Events.TravelOptions',
    defaultMessage: 'Travel Options',
  },
  accommodationOptions: {
    id: 'Events.AccommodationOptions',
    defaultMessage: 'Accommodation Options',
  },
  hubOptions: {
    id: 'Events.HubOptions',
    defaultMessage: 'Book your accommodation & travel',
  },
  optionsHubMessage: {
    id: 'Events.OptionsHubMessage',
    defaultMessage:
      'Secure your festival ticket, accommodation and travel all in one place.',
  },
  optionsHubTravelOption: {
    id: 'Events.OptionsHubTravelOption',
    defaultMessage: 'Travel',
  },
  optionsHubAccommodationOption: {
    id: 'Events.OptionsHubAccommodationOption',
    defaultMessage: 'Accommodation',
  },
  optionsHubBookLink: {
    id: 'Events.OptionsHubBookNow',
    defaultMessage: 'Book',
  },
  clearFilters: {
    id: 'Events.ClearFilters',
    defaultMessage: 'Clear filters',
  },
  new: {
    id: 'Events.New',
    defaultMessage: 'New',
  },
  dayParties: {
    id: 'Events.DayParties',
    defaultMessage: 'Day Parties',
  },
  clubNights: {
    id: 'Events.ClubNights',
    defaultMessage: 'Club Nights',
  },
  eventType: {
    id: 'Events.EventType',
    defaultMessage: 'Event type',
  },
  eventTypeWithCount: {
    id: 'Events.EventTypeWithCount',
    defaultMessage: 'Event type ({count})',
  },
  weveHandpickedParties: {
    id: 'Events.WeveHandpickedParties',
    defaultMessage:
      "We've handpicked some of the best parties happening near you.",
  },
  setTimesWillBeAvailable: {
    id: 'Events.SetTimesWillBeAvailable',
    defaultMessage: 'Set times will be available here during the event.',
  },
  noSavedEvents: {
    id: 'Events.NoSavedEvents',
    defaultMessage: 'No saved events',
  },
  toSaveEvent: {
    id: 'Events.ToSaveEvent',
    defaultMessage: 'To save an event tap the bookmark icon on an event.',
  },
  findEvents: {
    id: 'Events.FindEvents',
    defaultMessage: 'Find events',
  },
  usePreciseLocation: {
    id: 'Events.UsePreciseLocation',
    defaultMessage: 'Use precise location',
  },
  eventsNearYou: {
    id: 'Events.EventsNearYou',
    defaultMessage: 'Events near you',
  },
  resetToArea: {
    id: 'Events.ResetToArea',
    defaultMessage: 'Reset to {area}',
  },
  cannotFilterByCurrentLocation: {
    id: 'Events.CannotFilterByCurrentLocation',
    defaultMessage:
      'Cannot filter by current location until permission is granted',
  },
  showingEventsWithin: {
    id: 'Dict.ShowingEventsWithin',
    defaultMessage: 'Showing events within {distance}',
  },
  poweredBy: {
    id: 'Events.PoweredBy',
    defaultMessage: 'Powered by',
  },
  promoted: {
    id: 'Events.Promoted',
    defaultMessage: 'Promoted',
  },
  typeAreaName: {
    id: 'Events.TypeAreaName',
    defaultMessage: 'Type area name',
  },
})

export default messages
